import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface DistributionUser {
  nickname: string;
  mobile: string;
  add_balance: number;
  exp: number;
  created_at: string;
}

export interface Distribution {
  distribution_name: string;
  distribution_mobile: string;
  // surname: string;
  // email: string;
  // password: string;
  // api_token: string;
}

export interface DistributionInfo {
  distributions: Array<Distribution>;
  distribution: Distribution;
}

@Module
export default class DistributionModule extends VuexModule implements DistributionInfo {
  distribution_users = [] as Array<DistributionUser>;
  distribution_user_count = 0;
  distributions = [] as Array<Distribution>;
  distribution_count = 0;
  distribution = {} as Distribution;

  get currentDistribution(): Distribution {
    return this.distribution;
  }

  get currentDistributionUsers(): Array<DistributionUser> {
    return this.distribution_users;
  }

  get currentDistributionUserCount() {
    return this.distribution_user_count;
  }

  get currentDistributions(): Array<Distribution> {
    return this.distributions;
  }

  get currentDistributionCount() {
    return this.distribution_count;
  }

  // get getErrors() {
  //   return this.errors;
  // }

  @Mutation
  [Mutations.SET_DISTRIBUTION](distribution) {
    this.distribution = distribution;
  }

  @Mutation
  [Mutations.SET_DISTRIBUTION_USERS](distribution_users) {
    this.distribution_users = distribution_users;
  }

  @Mutation
  [Mutations.SET_DISTRIBUTION_USER_COUNT](distribution_user_count) {
    this.distribution_user_count = distribution_user_count;
  }

  @Mutation
  [Mutations.SET_DISTRIBUTIONS](distributions) {
    this.distributions = distributions;
  }

  @Mutation
  [Mutations.SET_DISTRIBUTION_COUNT](distribution_count) {
    this.distribution_count = distribution_count;
  }

  @Action
  [Actions.GET_DISTRIBUTIONS](params) {
    ApiService.setHeader();

    let queryString = "?";

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/distributions", queryString)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_DISTRIBUTIONS, data["distributions"]);
          this.context.commit(Mutations.SET_DISTRIBUTION_COUNT, data["total_result"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_DISTRIBUTION_USERS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/distributions/${params.distribution_id}/distribution_users`, queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_DISTRIBUTION_USERS,
            data["distribution_users"]
          );
          this.context.commit(
            Mutations.SET_DISTRIBUTION_USER_COUNT,
            data["total_result"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.GET_DISTRIBUTION](id) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/distributions/${id}`)
        .then(({ data }) => {
          console.log(data["distribution"]);
          this.context.commit(Mutations.SET_DISTRIBUTION, data["distribution"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_DISTRIBUTION](credentials) {
    ApiService.setHeader();

    const params = {
      distribution: {
        ...credentials,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/distributions`, params)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_DISTRIBUTION, data["distribution"]);
            this.context.commit(Mutations.SET_ERROR, null);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data["error_msg"]);
            resolve();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_DISTRIBUTION](credentials) {
    ApiService.setHeader();

    const params = {
      distribution: {
        ...credentials.values,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/distributions/${credentials.id}`, params)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_DISTRIBUTION, data["distribution"]);
            this.context.commit(Mutations.SET_ERROR, null);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data["error_msg"]);
            resolve();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_DISTRIBUTION](id) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`admin/distributions/${id}`)
        .then(({ data }) => {
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }

  @Action
  [Actions.DELETE_DISTRIBUTION_USER](params) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`admin/distributions/${params['distribution_id']}/distribution_users/${params['id']}`)
        .then(({ data }) => {
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }
}
