import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface GiftRecord {
  id: number;
  user_name: string;
  mobile: string;
  product_name: string;
  recycle_price: string;
}

export interface GiftRecordInfo {
  gift_records: Array<GiftRecord>;
  gift_record: GiftRecord;
}

@Module
export default class GiftRecordModule
  extends VuexModule
  implements GiftRecordInfo
{
  gift_records = [] as Array<GiftRecord>;
  gift_record_count = 0;
  gift_record = {} as GiftRecord;

  get currentGiftRecord(): GiftRecord {
    return this.gift_record;
  }

  get currentGiftRecords(): Array<GiftRecord> {
    return this.gift_records;
  }

  get currentGiftRecordCount() {
    return this.gift_record_count;
  }

  // get getErrors() {
  //   return this.errors;
  // }

  @Mutation
  [Mutations.SET_GIFT_RECORD](gift_record) {
    this.gift_record = gift_record
  }

  @Mutation
  [Mutations.SET_GIFT_RECORDS](gift_records) {
    this.gift_records = gift_records
  }

  @Mutation
  [Mutations.SET_GIFT_RECORD_COUNT](gift_record_count) {
    this.gift_record_count = gift_record_count
  }

  @Action
  [Actions.GET_GIFT_RECORDS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/gift_records", queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_GIFT_RECORDS,
            data["gift_records"]
          );
          this.context.commit(
            Mutations.SET_GIFT_RECORD_COUNT,
            data["total_result"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.GET_GIFT_RECORD](id) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/gift_records/${id}`)
        .then(({ data }) => {
          console.log(data["gift_record"])
          this.context.commit(
            Mutations.SET_GIFT_RECORD,
            data["gift_record"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_GIFT_RECORD](credentials) {
    ApiService.setHeader()

    const params = {
      gift_record: {
        ...credentials,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/gift_records`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_GIFT_RECORD,
            data["gift_record"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }
}
