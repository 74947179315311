import ApiService from "@/core/services/ApiService"
import JwtService from "@/core/services/JwtService"
import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators"
import { AxiosRequestConfig } from "axios"

export interface AddExtraPointRecord {
  id: number
  point: number
}

export interface AddExtraPointRecordInfo {
  add_extra_point_records: Array<AddExtraPointRecord>
  add_extra_point_record: AddExtraPointRecord
}

@Module
export default class AddExtraPointRecordModule
  extends VuexModule
  implements AddExtraPointRecordInfo
{
  add_extra_point_records = [] as Array<AddExtraPointRecord>
  add_extra_point_record_count = 0
  add_extra_point_record = {} as AddExtraPointRecord

  get currentAddExtraPointRecord(): AddExtraPointRecord {
    return this.add_extra_point_record
  }

  get currentAddExtraPointRecords(): Array<AddExtraPointRecord> {
    return this.add_extra_point_records
  }

  get currentAddExtraPointRecordCount() {
    return this.add_extra_point_record_count
  }

  // get getErrors() {
  //   return this.errors;
  // }

  @Mutation
  [Mutations.SET_ADD_EXTRA_POINT_RECORDS](add_extra_point_records) {
    this.add_extra_point_records = add_extra_point_records
  }

  @Mutation
  [Mutations.SET_ADD_EXTRA_POINT_RECORD](add_extra_point_record) {
    this.add_extra_point_record = add_extra_point_record
  }

  @Mutation
  [Mutations.SET_ADD_EXTRA_POINT_RECORD_COUNT](add_extra_point_record_count) {
    this.add_extra_point_record_count = add_extra_point_record_count
  }

  @Action
  [Actions.GET_ADD_EXTRA_POINT_RECORDS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i]
      })
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/add_extra_point_records`, queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_ADD_EXTRA_POINT_RECORDS,
            data["add_extra_point_records"]
          );
          this.context.commit(
            Mutations.SET_ADD_EXTRA_POINT_RECORD_COUNT,
            data["total_result"]
          );
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }

  @Action
  [Actions.CREATE_ADD_EXTRA_POINT_RECORD](params) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/add_extra_point_records`, params)
        .then(({ data }) => {
          if (!data.success) {
            this.context.commit(Mutations.SET_ERROR, data.error_msg)
            reject()
          }

          this.context.commit(
            Mutations.SET_ADD_EXTRA_POINT_RECORD,
            data["add_extra_point_record"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }

  @Action
  [Actions.ABORT_ADD_EXTRA_POINT_RECORD](params) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/add_extra_point_records/${params.id}/abort`, params)
        .then(({ data }) => {
          if (!data.success) {
            this.context.commit(Mutations.SET_ERROR, data.error_msg)
            reject()
          }

          // this.context.commit(
          //   Mutations.SET_ADD_EXTRA_POINT_RECORD,
          //   data["add_extra_point_record"]
          // );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }
}
