import ApiService from "@/core/services/ApiService"
import JwtService from "@/core/services/JwtService"
import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators"
import { AxiosRequestConfig } from "axios"

export interface PaymentRecord {
  id: number
  balance: number
}

export interface PaymentRecordInfo {
  payment_records: Array<PaymentRecord>
}

@Module
export default class PaymentRecordModule
  extends VuexModule
  implements PaymentRecordInfo
{
  payment_records = [] as Array<PaymentRecord>
  payment_record_count = 0
  download_payment_records = {} as any

  get currentPaymentRecords(): Array<PaymentRecord> {
    return this.payment_records
  }

  get currentPaymentRecordCount() {
    return this.payment_record_count
  }

  // get getErrors() {
  //   return this.errors;
  // }

  @Mutation
  [Mutations.SET_PAYMENT_RECORDS](payment_records) {
    this.payment_records = payment_records
  }

  @Mutation
  [Mutations.SET_PAYMENT_RECORD_COUNT](payment_record_count) {
    this.payment_record_count = payment_record_count
  }

  @Action
  [Actions.GET_PAYMENT_RECORDS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i]
      })
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/payment_records`, queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_PAYMENT_RECORDS,
            data["payment_records"]
          );
          this.context.commit(
            Mutations.SET_PAYMENT_RECORD_COUNT,
            data["total_result"]
          );
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }
}
