import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface SystemSettingImage {
  id: number,
  image: string,
  image_url: string,
  link: string,
}

export interface SystemSetting {
  id: number,
  title: string,
  key: string,
  value: string,
  system_setting_images_attributes: Array<SystemSettingImage>,
}

export interface SystemSettingInfo {
  system_setting: SystemSetting,
}

@Module
export default class CouponItemModule
  extends VuexModule
  implements SystemSettingInfo
{
  system_setting = {} as SystemSetting
  summary = {}
  water_list = []
  loser_list = []
  card_records = []
  card_record_count = 0
  water_items = []
  water_item_count = 0
  loser_items = []
  loser_item_count = 0
  download_loser_items = {} as any

  get currentSystemSetting(): SystemSetting {
    return this.system_setting
  }

  get currentSummary(): any {
    return this.summary
  }

  get currentWaterList(): any {
    return this.water_list
  }

  get currentLoserList(): any {
    return this.loser_list
  }

  get currentCardRecords(): any {
    return this.card_records
  }

  get currentCardRecordCount() {
    return this.card_record_count
  }

  get currentWaterItems(): any {
    return this.water_items
  }

  get currentWaterItemCount() {
    return this.water_item_count
  }

  get currentLoserItems(): any {
    return this.loser_items
  }

  get currentLoserItemCount() {
    return this.loser_item_count
  }

  get currentDownloadLoserItems(){
    return this.download_loser_items
  }

  @Mutation
  [Mutations.SET_SYSTEM_SETTING](system_setting) {
    this.system_setting = system_setting
  }

  @Mutation
  [Mutations.SET_SUMMARY](summary) {
    this.summary = summary
  }

  @Mutation
  [Mutations.SET_WATER_LIST](water_list) {
    this.water_list = water_list
  }

  @Mutation
  [Mutations.SET_LOSER_LIST](loser_list) {
    this.loser_list = loser_list
  }

  @Mutation
  [Mutations.SET_CARD_RECORDS](card_records) {
    this.card_records = card_records
  }

  @Mutation
  [Mutations.SET_CARD_RECORD_COUNT](card_record_count) {
    this.card_record_count = card_record_count
  }

  @Mutation
  [Mutations.SET_WATER_ITEMS](water_items) {
    this.water_items = water_items
  }

  @Mutation
  [Mutations.SET_WATER_ITEM_COUNT](water_item_count) {
    this.water_item_count = water_item_count
  }

  @Mutation
  [Mutations.SET_LOSER_ITEMS](loser_items) {
    this.loser_items = loser_items
  }

  @Mutation
  [Mutations.SET_LOSER_ITEM_COUNT](loser_item_count) {
    this.loser_item_count = loser_item_count
  }

  @Mutation
  [Mutations.SET_DOWNLOAD_LOSER_ITEMS](download_loset_items) {
    this.download_loser_items = download_loset_items
  }

  @Action
  [Actions.GET_SYSTEM_SETTING](key) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/system_settings/${key}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SYSTEM_SETTING, data["system_setting"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_SYSTEM_SETTING](params) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/system_settings/${params.system_setting.key}`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_SYSTEM_SETTING,
            data["system_setting"]
          );
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        });
    })
  }

  @Action
  [Actions.GET_SUMMARY]() {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/system_settings/get_summary`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SUMMARY, data["summary"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_WATER_LIST](params) {
    ApiService.setHeader();

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/system_settings/get_water_list`, queryString)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_WATER_LIST, data["water_list"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_LOSER_LIST](params) {
    ApiService.setHeader();

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/system_settings/get_loser_list`, queryString)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LOSER_LIST, data["loser_list"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_CARD_RECORDS](params) {
    ApiService.setHeader();

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/card_records`, queryString)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CARD_RECORDS, data["card_records"]);
          this.context.commit(Mutations.SET_CARD_RECORD_COUNT, data["total_result"]);
          resolve();
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_WATER_ITEMS](params) {
    ApiService.setHeader();

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/water_items`, queryString)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_WATER_ITEMS, data["water_items"]);
          this.context.commit(Mutations.SET_WATER_ITEM_COUNT, data["total_result"]);
          resolve();
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_LOSER_ITEMS](params) {
    ApiService.setHeader();

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/loser_items`, queryString)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LOSER_ITEMS, data["loser_items"]);
          this.context.commit(Mutations.SET_LOSER_ITEM_COUNT, data["total_result"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_DOWNLOAD_LOSER_ITEMS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i]
      })
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/loser_items/download_excel", queryString, {responseType: 'blob'})
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_DOWNLOAD_LOSER_ITEMS,
            data
          )
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }
}
